import React, { Component } from 'react';
import { isMobileOnly, isBrowser, isTablet, CustomView } from 'react-device-detect';

class GoogleCampaign extends Component {

    componentWillReceiveProps(nextProps) {
    }

    render() {
        return (
            <div id='otherSubview' className="container-fluid">
                
                    <div className="row">

                        <div className="col-xxl-6">
                            <div className="container-fluid" >
                                <div className="row">
                                    <div className="col">
                                        <h2>Google-Werbung</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <CustomView condition={isBrowser || isTablet} >
                                            <article>
                                                Vielleicht haben Sie es selber schon ausprobiert oder eine Agentur beauftragt: Anzeigen schalten bei Google. Früher hieß das Google AdWords, heute einfach Google Ads. <br /> <br />
                                                Damit eine Google-Kampagne erfolgreich ist, brauchen Sie professionelle Unterstützung. Sonst kann es sein, dass Sie Ihr Geld „verbrennen“, weil Sie viel zu viel für einen Klick bezahlen. 
                                                Oder weil Sie nicht Ihre Bedarfsgruppen erreichen. Wir wissen, wie Ihre potenziellen Kunden ticken und wie sie bei Google suchen. Und wir wissen, wie Sie mit Nischen-Keywords von niedrigen Klickpreisen profitieren. <br /> <br />
                                                Auch wenn das System der Google-Anzeigen mittlerweile sehr komplex ist, erklären wir Ihnen unsere Arbeit und unsere Ziele leicht verständlich. Sie bestimmen das monatliche Budget und erhalten eine 
                                                transparente Aufstellung der Kosten sowie der erzielten Ergebnisse. Schon mit wenigen Euro können Sie einen Test starten. Sprechen Sie uns einfach an!
                                            </article>
                                        </CustomView>
                                        <CustomView condition={isMobileOnly} >
                                            <article>
                                                <u>Für eine erfolgreiche Google-Kampagne,</u> brauchen Sie professionelle Unterstützung. Sonst kann es sein, dass Sie viel zu viel für einen Klick bezahlen. Oder Sie erreichen nicht Ihre Bedarfsgruppen. 
                                                Wir wissen, wie Ihre potenziellen Kunden bei Google suchen. Und wie Sie mit Nischen-Keywords von kleinen Klickpreisen profitieren. Bestimmen Sie das monatliche Budget. Sie erhalten von uns eine 
                                                transparente Aufstellung der Kosten und der erzielten Ergebnisse. Schon mit wenigen Euro können Sie es testen. Sprechen Sie uns einfach an!
                                                </article>
                                        </CustomView>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-6 d-none d-xxl-block" style={{backgroundImage:`url(${require('../../../media/img/AdWords-min.png')}), none`, backgroundRepeat:'no-repeat', backgroundSize:'contain cover', backgroundPosition: '-30% 30%'}}>
                        </div>
                    
                    </div>
                </div>
        );
    }
}

export default GoogleCampaign;
