import About from './About';
import AutohausMarketing  from './subviews/AutohausMarketing';
import Website  from './subviews/Website';
import GoogleCampaign  from './subviews/GoogleCampaign';
import ECommerce  from './subviews/ECommerce';
import Videos  from './subviews/Videos';
import OnlineMarketing  from './subviews/OnlineMarketing';


export default About;
export { AutohausMarketing, Website, GoogleCampaign, ECommerce, Videos, OnlineMarketing };