import axios from 'axios';
import jquery from 'jquery';
import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

import Impressum from './subviews/Impressum';
import Datenschutz from './subviews/Datenschutz';

import './Contact.scss';

const MAIL_API_PATH = '/api/contact/index.php';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            impressumOpen: false,
            datenschutzOpen: false,

            // contact form vars
            fullname: '',
            emailtel: '',
            message: '',
            subject: 'Ich interessiere mich für Ihre Leistungen. Bitte kontaktieren Sie mich.',
            datenschutzakzeptiert: false,
            mailSent: false,
            submitted: false,
            error: null
        };

        this.onOpenImpressumModal = this.onOpenImpressumModal.bind(this);
        this.onOpenDatenschutzModal = this.onOpenDatenschutzModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);

    }

    // form submit handling
    handleFormSubmit( event ) {

        //console.log(this.state);

        event.preventDefault();
        axios({
            method: 'post',
            url: `${MAIL_API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: this.state
        })
            .then(result => {
                this.setState({
                    submitted: true,
                    mailSent: result.data.sent
                })
            })
            .catch(error => this.setState({ error: error.message })
            );
    }

    showContactForm( event ) {
        document.getElementById("gw-contact-form-overlay").classList.add("visisble");
    }

    componentWillReceiveProps(nextProps) {
    }

    onOpenImpressumModal = () => {
        this.setState({ impressumOpen: true });
        this.props.blockScrolling(true);
    };

    onOpenDatenschutzModal = () => {
        this.setState({ datenschutzOpen: true });
        this.props.blockScrolling(true);
    };

    onCloseModal = () => {
        this.setState({ impressumOpen: false, datenschutzOpen: false });
        this.props.blockScrolling(false);
    };

    render() {
        const { datenschutzOpen, impressumOpen } = this.state;

        return (
            <React.Fragment>
                <div id="contact" className="container-fluid dark-theme gw-contact-form-wrapper" style={{backgroundImage:`url('${require('../../media/img/olaf_1920-min-min.jpg')}')`, backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundSize:'cover'}}>
                    <div id="gw-contact-form-overlay" className="gw-contact-form-overlay">
                        <form action="#">
                            <label className={"required" + (!this.state.fullname && this.state.submitted ? ' gw-error' : '')}>Name
                                <input type="text" id="fullname" name="fullname" placeholder=""
                                       value={this.state.fullname}
                                       onChange={e => this.setState({ fullname: e.target.value })}
                                />
                            </label>


                            <label className={"required" +  (!this.state.emailtel && this.state.submitted ? ' gw-error' : '')}>Telefon / E-Mail-Adresse
                                <input type="text" id="emailtel" name="emailtel" placeholder=""
                                       value={this.state.emailtel}
                                       onChange={e => this.setState({ emailtel: e.target.value })}
                                />
                            </label>

                            <label class="gw-message-label">
                                <span>Nachricht</span>
                                <textarea id="message" name="message" placeholder=""
                                          onChange={e => this.setState({ message: e.target.value })}
                                          value={this.state.message}
                                ></textarea>
                            </label>

                            <label>
                                <span>Nachricht</span>
                                <textarea id="subject" name="subject" placeholder=""
                                          onChange={e => this.setState({ subject: e.target.value })}
                                          value={this.state.subject}
                                ></textarea>
                            </label>

                            <label className={(!this.state.datenschutzakzeptiert && this.state.submitted ? 'gw-error' : '')}>
                                <input type="checkbox" id="datenschutzakzeptiert" name="datenschutzakzeptiert" value="Datenschutzerklärung gelesen und Verarbeitung der Daten zur Beantwortung der Anfrage zugestimmt."
                                       onChange={e => this.setState({ datenschutzakzeptiert: (e.target.checked?e.target.value:false)})}
                                />
                                <span>Datenschutzerklärung gelesen und Verarbeitung der Daten zur Beantwortung der Anfrage zugestimmt.</span>
                            </label>

                            <br />

                            <div className="messages">
                                {this.state.submitted && !this.state.mailSent &&
                                    <div className="gw-error">Ihre Nachricht konnte nicht versendet werden.</div>
                                }
                                {this.state.error &&
                                    <div className="gw-error">
                                        {this.state.error}
                                    </div>
                                }
                            </div>

                            {!this.state.mailSent &&
                                <div class="gw-contact-form-send-button-wrapper">
                                <button type="submit" className="btn w-primary gw-contact-form-send-button" id="Kontakt_Anfrage" onClick={e => this.handleFormSubmit(e)}>Abschicken</button>
                                </div>
                            }

                            {this.state.submitted && this.state.mailSent &&
                                <div className="gw-success" id="contact-form-send-message"><span>Danke, dass Sie uns kontaktiert haben.</span></div>
                            }
                        </form>
                    </div>


                    <div id="contact-header" className="row align-items-center">
                        <div className="col">
                            <h1>
                                Lassen Sie uns
                            </h1>
                            <h1>
                                <b>zusammen arbeiten.</b>
                            </h1>

                            <button type="button" className="btn w-primary" onClick={e => this.showContactForm(e)}>
                                Kontakt
                            </button>
                        </div>
                    </div>

                    <div id="contact-footer" className="row justify-content-center align-items-center">
                        <div className="col-xxxs-4">
                            <button onClick={this.onOpenImpressumModal}>Impressum</button>
                            <Modal open={impressumOpen} onClose={this.onCloseModal} >
                                <Impressum />
                            </Modal>
                        </div>
                        <div className="col-xxxs-4">
                            <button onClick={this.onOpenDatenschutzModal}>Datenschutz</button>
                            <Modal open={datenschutzOpen} onClose={this.onCloseModal} center={true} blockScroll={true}>
                                <Datenschutz />
                            </Modal>
                        </div>
                    </div>

                </div>
            </ React.Fragment>
        );
    }
}

export default Contact;
