import React, { Component } from 'react';
import { isMobileOnly, isBrowser, isTablet, CustomView } from 'react-device-detect';

import './Presentation.scss';


class Presentation extends Component {
    render() {
        return (
            <div id="presentation" className="container-fluid light-theme">
                <div className="row" style={{height:'100vh'}}>
                    
                    <div id="presentation-body" className="col">
                        <video 
                                id='presentation-video'
                                loop={false}
                                autoPlay={false}
                                muted={false}
                                controls={true}
                                playsInline={true}
                                preload='meta'
                                type='video/mp4'
                            >
                            <source src={require('../../media/assets/Agentur_5W-50_Automobilmarketing.mp4')} />
                            <source src={require('../../media/assets/Agentur_5W-50_Automobilmarketing.webm')} />
                        </video>
                    </div>

                    <div id="presentation-side" className="col">

                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1>
                                        Praktische Tipps und Inspirationen
                                    </h1>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col">
                                    <CustomView condition={isBrowser || isTablet} >
                                        <p>
                                            Der VW-Konzern lud seine Händler zum Marketingforum nach Dresden in die gläserne Manufaktur ein. Auch Olaf Dicker, 
                                            Inhaber von 5W-50, gehörte zu den Sprechern, die den Händlern wertvolle praktische Tipps mit auf den Weg gaben.
                                        </p>
                                    </CustomView>
                                    <CustomView condition={isMobileOnly} >
                                        <p>
                                            VW-Händler trafen sich zum Marketingforum in der gläsernen Manufaktur in Dresden. 
                                            Olaf Dicker, Inhaber von 5W-50, gab den Händlern wertvolle praktische Tipps. 
                                        </p>
                                    </CustomView>
                                </div>
                            </div>
                        </div>

                     </div>
                </div>
            </div>
        );
    }
}

export default Presentation;