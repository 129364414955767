import React, { Component } from 'react';

class Datenschutz extends Component {
    
  render() {
    return (
        <main>
          <h1>Datenschutz</h1>
					<article>
            <p>Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.</p>
            <p>Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.</p>
            <p>Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener Verantwortung verarbeiten.</p>
            <p>Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>
            <p>I. Informationen über uns als Verantwortliche<br /> II. Rechte der Nutzer und Betroffenen<br /> III. Informationen zur Datenverarbeitung</p>
            <h3>I. Informationen über uns als Verantwortliche</h3>
            <p>Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</p>
            <p>Agentur 5W-50<br />
            Dipl. Kommunikationswirt Olaf Dicker<br />
            Gleditschstraße 75<br />
            10823 Berlin<br />
            <br /><br />
            030 91 55 84 08<br />
            info@5w-50.de</p>
            <h3>II. Rechte der Nutzer und Betroffenen</h3>
            <p>Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das Recht</p>
            <ul>
            <li>auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15 DSGVO);</li>
            <li>auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch Art. 16 DSGVO);</li>
            <li>auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;</li>
            <li>auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);</li>
            <li>auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).</li>
            </ul>
            <p>Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder die Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft über diese Empfänger.</p>
            <p>Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.</p>
            <h3>III. Informationen zur Datenverarbeitung</h3>
            <p>Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.</p>
            <h3>Serverdaten</h3>
            <p>Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und Version Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts, die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben.</p>
            <p>Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.</p>
            <p>Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.</p>
            <p>Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.</p>
            <h3>Cookies</h3>
            <p><strong>a) Sitzungs-Cookies/Session-Cookies</strong></p>
            <p>Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder andere Speichertechnologien, die durch den von Ihnen eingesetzten Internet-Browser auf Ihrem Endgerät ablegt und gespeichert werden. Durch diese Cookies werden im individuellen Umfang bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten oder Ihre IP-Adresse, verarbeitet. </p>
            <p>Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver und sicherer, da die Verarbeitung bspw. die Wiedergabe unseres Internetauftritts in unterschiedlichen Sprachen oder das Angebot einer Warenkorbfunktion ermöglicht.</p>
            <p>Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder Vertragsabwicklung verarbeitet werden.</p>
            <p>Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser berechtigtes Interesse in der Verbesserung der Funktionalität unseres Internetauftritts. Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.</p>
            <p>Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies gelöscht.</p>
            <p><strong>b) Drittanbieter-Cookies</strong></p>
            <p>Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit denen wir zum Zwecke der Werbung, der Analyse oder der Funktionalitäten unseres Internetauftritts zusammenarbeiten, verwendet.</p>
            <p>Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden Informationen.</p>
            <p><strong>c) Beseitigungsmöglichkeit</strong></p>
            <p>Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers verhindern oder einschränken. Ebenfalls können Sie bereits gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support. Bei sog. Flash-Cookies kann die Verarbeitung allerdings nicht über die Einstellungen des Browsers unterbunden werden. Stattdessen müssen Sie insoweit die Einstellung Ihres Flash-Players ändern. Auch die hierfür erforderlichen Schritte und Maßnahmen hängen von Ihrem konkret genutzten Flash-Player ab. Bei Fragen benutzen Sie daher bitte ebenso die Hilfefunktion oder Dokumentation Ihres Flash-Players oder wenden sich an den Hersteller bzw. Benutzer-Support.</p>
            <p>Sollten Sie die Installation der Cookies verhindern oder einschränken, kann dies allerdings dazu führen, dass nicht sämtliche Funktionen unseres Internetauftritts vollumfänglich nutzbar sind.</p>
            <h3>Newsletter</h3>
            <p>Falls Sie sich für unseren kostenlosen Newsletter anmelden, werden die von Ihnen hierzu abgefragten Daten, also Ihre E-Mail-Adresse sowie - optional - Ihr Name und Ihre Anschrift, an uns übermittelt. Gleichzeitig speichern wir die IP-Adresse des Internetanschlusses von dem aus Sie auf unseren Internetauftritt zugreifen sowie Datum und Uhrzeit Ihrer Anmeldung. Im Rahmen des weiteren Anmeldevorgangs werden wir Ihre Einwilligung in die Übersendung des Newsletters einholen, den Inhalt konkret beschreiben und auf diese Datenschutzerklärung verwiesen. Die dabei erhobenen Daten verwenden wir ausschließlich für den Newsletter-Versand – sie werden deshalb insbesondere auch nicht an Dritte weitergegeben.</p>
            <p>Rechtsgrundlage hierbei ist Art. 6 Abs. 1 lit. a) DSGVO.</p>
            <p>Die Einwilligung in den Newsletter-Versand können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft widerrufen. Hierzu müssen Sie uns lediglich über Ihren Widerruf in Kenntnis setzen oder den in jedem Newsletter enthaltenen Abmeldelink betätigen.</p>
            <h3>Kontaktanfragen / Kontaktmöglichkeit</h3>
            <p>Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von Ihnen angegebenen Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und Beantwortung Ihre Anfrage erforderlich - ohne deren Bereitstellung können wir Ihre Anfrage nicht oder allenfalls eingeschränkt beantworten.</p>
            <p>Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
            <p>Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich etwaig anschließenden Vertragsabwicklung.</p>
            <h3>Google Analytics</h3>
            <p>In unserem Internetauftritt setzen wir Google Analytics ein. Hierbei handelt es sich um einen Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.</p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“) <a style={{color:'#999'}} href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active</a> garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p>Der Dienst Google Analytics dient zur Analyse des Nutzungsverhaltens unseres Internetauftritts. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.</p>
            <p>Nutzungs- und nutzerbezogene Informationen, wie bspw. IP-Adresse, Ort, Zeit oder Häufigkeit des Besuchs unseres Internetauftritts, werden dabei an einen Server von Google in den USA übertragen und dort gespeichert. Allerdings nutzen wir Google Analytics mit der sog. Anonymisierungsfunktion. Durch diese Funktion kürzt Google die IP-Adresse schon innerhalb der EU bzw. des EWR.</p>
            <p>Die so erhobenen Daten werden wiederum von Google genutzt, um uns eine Auswertung über den Besuch unseres Internetauftritts sowie über die dortigen Nutzungsaktivitäten zur Verfügung zu stellen. Auch können diese Daten genutzt werden, um weitere Dienstleistungen zu erbringen, die mit der Nutzung unseres Internetauftritts und der Nutzung des Internets zusammenhängen.</p>
            <p>Google gibt an, Ihre IP-Adresse nicht mit anderen Daten zu verbinden. Zudem hält Google unter <a style={{color:'#999'}} href="https://www.google.com/intl/de/policies/privacy/partners">https://www.google.com/intl/de/policies/privacy/partners</a> weitere datenschutzrechtliche Informationen für Sie bereit, so bspw. auch zu den Möglichkeiten, die Datennutzung zu unterbinden.</p>
            <p>Zudem bietet Google unter <a style={{color:'#999'}} href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a> ein sog. Deaktivierungs-Add-on nebst weiteren Informationen hierzu an. Dieses Add-on lässt sich mit den gängigen Internet-Browsern installieren und bietet Ihnen weitergehende Kontrollmöglichkeit über die Daten, die Google bei Aufruf unseres Internetauftritts erfasst. Dabei teilt das Add-on dem JavaScript (ga.js) von Google Analytics mit, dass Informationen zum Besuch unseres Internetauftritts nicht an Google Analytics übermittelt werden sollen. Dies verhindert aber nicht, dass Informationen an uns oder an andere Webanalysedienste übermittelt werden. Ob und welche weiteren Webanalysedienste von uns eingesetzt werden, erfahren Sie natürlich ebenfalls in dieser Datenschutzerklärung.</p>
            <h3>„Google+“-Social-Plug-in</h3>
            <p>In unserem Internetauftritt setzen wir das Plug-in des Social-Networks Google+ („Google Plus“) ein. Bei Google+ handelt es sich um einen Internetservice der Google LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.</p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“) <a style={{color:'#999'}} href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active</a> garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Qualitätsverbesserung unseres Internetauftritts.</p>
            <p>Weitergehende Informationen über die möglichen Plug-ins sowie über deren jeweilige Funktionen hält Google unter <a style={{color:'#999'}} href="https://developers.google.com/+/web/">https://developers.google.com/+/web/</a> für Sie bereit.</p>
            <p>Sofern das Plug-in auf einer der von Ihnen besuchten Seiten unseres Internetauftritts hinterlegt ist, lädt Ihr Internet-Browser eine Darstellung des Plug-ins von den Servern von Google in den USA herunter. Aus technischen Gründen ist es dabei notwendig, dass Google Ihre IP-Adresse verarbeitet. Daneben werden aber auch Datum und Uhrzeit des Besuchs unserer Internetseiten erfasst.</p>
            <p>Sollten Sie bei Google eingeloggt sein, während Sie eine unserer mit dem Plug-in versehenen Internetseite besuchen, werden die durch das Plug-in gesammelten Informationen Ihres konkreten Besuchs von Google erkannt. Die so gesammelten Informationen weist Google womöglich Ihrem dortigen persönlichen Nutzerkonto zu. Sofern Sie also bspw. den sog. „Teilen“-Button von Google benutzen, werden diese Informationen in Ihrem Google-Nutzerkonto gespeichert und ggf. über die Plattform von Google veröffentlicht. Wenn Sie das verhindern möchten, müssen Sie sich entweder vor dem Besuch unseres Internetauftritts bei Google ausloggen oder die entsprechenden Einstellungen in Ihrem Google-Benutzerkonto vornehmen.</p>
            <p>Weitergehende Informationen über die Erhebung und Nutzung von Daten sowie Ihre diesbezüglichen Rechte und Schutzmöglichkeiten hält Google in den unter <a style={{color:'#999'}} href="https://policies.google.com/privacy">https://policies.google.com/privacy</a> abrufbaren Datenschutzhinweisen bereit.</p>
            <h3>Google-Maps</h3>
            <p>In unserem Internetauftritt setzen wir Google Maps zur Darstellung unseres Standorts sowie zur Erstellung einer Anfahrtsbeschreibung ein. Es handelt sich hierbei um einen Dienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.</p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“) <a style={{color:'#999'}} href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active</a> garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p>Um die Darstellung bestimmter Schriften in unserem Internetauftritt zu ermöglichen, wird bei Aufruf unseres Internetauftritts eine Verbindung zu dem Google-Server in den USA aufgebaut.</p>
            <p>Sofern Sie die in unseren Internetauftritt eingebundene Komponente Google Maps aufrufen, speichert Google über Ihren Internet-Browser ein Cookie auf Ihrem Endgerät. Um unseren Standort anzuzeigen und eine Anfahrtsbeschreibung zu erstellen, werden Ihre Nutzereinstellungen und -daten verarbeitet. Hierbei können wir nicht ausschließen, dass Google Server in den USA einsetzt.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Optimierung der Funktionalität unseres Internetauftritts.</p>
            <p>Durch die so hergestellte Verbindung zu Google kann Google ermitteln, von welcher Website Ihre Anfrage gesendet worden ist und an welche IP-Adresse die Anfahrtsbeschreibung zu übermitteln ist.</p>
            <p>Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Installation der Cookies durch die entsprechenden Einstellungen in Ihrem Internet-Browser zu verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem Punkt „Cookies“.</p>
            <p>Zudem erfolgt die Nutzung von Google Maps sowie der über Google Maps erlangten Informationen nach den <a style={{color:'#999'}} href="http://www.google.de/accounts/TOS">Google-Nutzungsbedingungen</a> <a style={{color:'#999'}} href="https://policies.google.com/terms?gl=DE[&amp;]hl=de">https://policies.google.com/terms?gl=DE[&amp;]hl=de</a> und den <a style={{color:'#999'}} href="http://www.google.com/intl/de_de/help/terms_maps.html">Geschäftsbedingungen für Google Maps</a> https://www.google.com/intl/de_de/help/terms_maps.html.</p>
            <p>Überdies bietet Google unter <a style={{color:'#999'}} href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a> und <a style={{color:'#999'}} href="https://policies.google.com/privacy">https://policies.google.com/privacy</a> weitergehende Informationen an.</p>
            <h3>Google reCAPTCHA</h3>
            <p>In unserem Internetauftritt setzen wir Google reCAPTCHA zur Überprüfung und Vermeidung von Interaktionen auf unserer Internetseite durch automatisierte Zugriffe, bspw. durch sog. Bots, ein. Es handelt sich hierbei um einen Dienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.</p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“) <a style={{color:'#999'}} href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active</a> garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p>Durch diesen Dienst kann Google ermitteln, von welcher Webseite eine Anfrage gesendet wird sowie von welcher IP-Adresse aus Sie die sog. reCAPTCHA-Eingabebox verwenden. Neben Ihrer IP-Adresse werden womöglich noch weitere Informationen durch Google erfasst, die für das Angebot und die Gewährleistung dieses Dienstes notwendig sind.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Sicherheit unseres Internetauftritts sowie in der Abwehr unerwünschter, automatisierter Zugriffe in Form von Spam o.ä..</p>
            <p>Google bietet unter <a style={{color:'#999'}} href="https://policies.google.com/privacy">https://policies.google.com/privacy</a> weitergehende Informationen zu dem allgemeinen Umgang mit Ihren Nutzerdaten an.</p>
            <h3>Google Fonts</h3>
            <p>In unserem Internetauftritt setzen wir Google Fonts zur Darstellung externer Schriftarten ein. Es handelt sich hierbei um einen Dienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.</p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“) <a style={{color:'#999'}} href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active</a> garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p>Um die Darstellung bestimmter Schriften in unserem Internetauftritt zu ermöglichen, wird bei Aufruf unseres Internetauftritts eine Verbindung zu dem Google-Server in den USA aufgebaut.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.</p>
            <p>Durch die bei Aufruf unseres Internetauftritts hergestellte Verbindung zu Google kann Google ermitteln, von welcher Website Ihre Anfrage gesendet worden ist und an welche IP-Adresse die Darstellung der Schrift zu übermitteln ist.</p>
            <p>Google bietet unter <a style={{color:'#999'}} href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a> und <a style={{color:'#999'}} href="https://policies.google.com/privacy">https://policies.google.com/privacy</a> weitere Informationen an und zwar insbesondere zu den Möglichkeiten der Unterbindung der Datennutzung.</p>
            <h3>„Facebook“-Social-Plug-in</h3>
            <p>In unserem Internetauftritt setzen wir das Plug-in des Social-Networks Facebook ein. Bei Facebook handelt es sich um einen Internetservice der facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA. In der EU wird dieser Service wiederum von der Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland, betrieben, nachfolgend beide nur „Facebook“ genannt.</p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“) <a style={{color:'#999'}} href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC[&amp;]status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC[&amp;]status=Active</a> garantiert Facebook, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Qualitätsverbesserung unseres Internetauftritts.</p>
            <p>Weitergehende Informationen über die möglichen Plug-ins sowie über deren jeweilige Funktionen hält Facebook unter <a style={{color:'#999'}} href="https://developers.facebook.com/docs/plugins/">https://developers.facebook.com/docs/plugins/</a> für Sie bereit.</p>
            <p>Sofern das Plug-in auf einer der von Ihnen besuchten Seiten unseres Internetauftritts hinterlegt ist, lädt Ihr Internet-Browser eine Darstellung des Plug-ins von den Servern von Facebook in den USA herunter. Aus technischen Gründen ist es dabei notwendig, dass Facebook Ihre IP-Adresse verarbeitet. Daneben werden aber auch Datum und Uhrzeit des Besuchs unserer Internetseiten erfasst.</p>
            <p>Sollten Sie bei Facebook eingeloggt sein, während Sie eine unserer mit dem Plug-in versehenen Internetseite besuchen, werden die durch das Plug-in gesammelten Informationen Ihres konkreten Besuchs von Facebook erkannt. Die so gesammelten Informationen weist Facebook womöglich Ihrem dortigen persönlichen Nutzerkonto zu. Sofern Sie also bspw. den sog. „Gefällt mir“-Button von Facebook benutzen, werden diese Informationen in Ihrem Facebook-Nutzerkonto gespeichert und ggf. über die Plattform von Facebook veröffentlicht. Wenn Sie das verhindern möchten, müssen Sie sich entweder vor dem Besuch unseres Internetauftritts bei Facebook ausloggen oder durch den Einsatz eines Add-ons für Ihren Internetbrowser verhindern, dass das Laden des Facebook-Plug-in blockiert wird.</p>
            <p>Weitergehende Informationen über die Erhebung und Nutzung von Daten sowie Ihre diesbezüglichen Rechte und Schutzmöglichkeiten hält Facebook in den unter <a style={{color:'#999'}} href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a> abrufbaren Datenschutzhinweisen bereit.</p>
            <h3>„Twitter“-Social-Plug-in</h3>
            <p>In unserem Internetauftritt setzen wir das Plug-in des Social-Networks Twitter ein. Bei Twitter handelt es sich um einen Internetservice der Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA, nachfolgend nur „Twitter“ genannt.</p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“) <a style={{color:'#999'}} href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO[&amp;]status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO[&amp;]status=Active</a> garantiert Twitter, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Qualitätsverbesserung unseres Internetauftritts.</p>
            <p>Sofern das Plug-in auf einer der von Ihnen besuchten Seiten unseres Internetauftritts hinterlegt ist, lädt Ihr Internet-Browser eine Darstellung des Plug-ins von den Servern von Twitter in den USA herunter. Aus technischen Gründen ist es dabei notwendig, dass Twitter Ihre IP-Adresse verarbeitet. Daneben werden aber auch Datum und Uhrzeit des Besuchs unserer Internetseiten erfasst.</p>
            <p>Sollten Sie bei Twitter eingeloggt sein, während Sie eine unserer mit dem Plug-in versehenen Internetseite besuchen, werden die durch das Plug-in gesammelten Informationen Ihres konkreten Besuchs von Twitter erkannt. Die so gesammelten Informationen weist Twitter womöglich Ihrem dortigen persönlichen Nutzerkonto zu. Sofern Sie also bspw. den sog. „Teilen“-Button von Twitter benutzen, werden diese Informationen in Ihrem Twitter-Nutzerkonto gespeichert und ggf. über die Plattform von Twitter veröffentlicht. Wenn Sie das verhindern möchten, müssen Sie sich entweder vor dem Besuch unseres Internetauftritts bei Twitter ausloggen oder die entsprechenden Einstellungen in Ihrem Twitter-Benutzerkonto vornehmen.</p>
            <p>Weitergehende Informationen über die Erhebung und Nutzung von Daten sowie Ihre diesbezüglichen Rechte und Schutzmöglichkeiten hält Twitter in den unter <a style={{color:'#999'}} href="https://twitter.com/privacy">https://twitter.com/privacy</a> abrufbaren Datenschutzhinweisen bereit.</p>
            <h3>Zählpixel der Verwertungsgesellschaft WORT (VG WORT)</h3>
            <p>In unserem Internetauftritt setzen wir das sog. Zählpixel der VG WORT des Anbieters INFOnline GmbH, Forum Bonn Nord, Brühler Str. 9, 53119 Bonn, ein und zwar in Form des sog. SZM (Skalierbares Zentrales Messverfahren).</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts sowie in der Berücksichtigung urheberrechtlicher Interessen bezüglich der Inhalte unseres Internetauftritts.</p>
            <p>Bei dem Zählpixel handelt es sich um eine Grafik, die in unserem Internetauftritt platziert ist und die statistische Kennzahlen ermittelt. Hierdurch wird es möglich, die Kopierwahrscheinlichkeit von Texten anhand der Anzahl der Aufrufe und der konkreten Inhalte unseres Internetauftritts zu berechnen. Die Erhebung dieser Daten erfolgt anonym. Um die Zugriffszahlen sowie Ihren ggf. wiederkehrenden Besuch erfassen zu können, wird entweder ein sog. Session-Cookie durch Ihren Internet-Browser auf Ihrem Endgerät abgelegt oder eine Signatur, die aus verschiedenen Informationen Ihres Internet-Browsers (bspw. Useragent, Bildschirmauflösung, o.ä.) erstellt wird, eingesetzt. Ihre IP-Adresse wird jedoch nur in anonymisierter Form verarbeitet. Als einzelner Nutzer sind Sie dabei zu keinem Zeitpunkt identifizierbar.</p>
            <p>Falls Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Speicherung des Cookies durch eine Einstellung in Ihrem Internet-Browsers zu verhindern. Nähere Informationen hierzu finden Sie vorstehend unter „Cookies“. Darüber hinaus haben Sie die Möglichkeit, die SZM-Messung im Wege des sog. Opt-outs zu beenden. Mit dem Bestätigen des Links <a style={{color:'#999'}} href="https://optout.ioam.de/">https://optout.ioam.de/</a> wird über Ihren Internet-Browser ein Cookie auf Ihrem Endgerät gespeichert, das die weitere Analyse verhindert. Bitte beachten Sie aber, dass Sie den obigen Link erneut betätigen müssen, sofern Sie die auf Ihrem Endgerät gespeicherten Cookies löschen.</p>
            <h3>YouTube</h3>
            <p>In unserem Internetauftritt setzen wir YouTube ein. Hierbei handelt es sich um ein Videoportal der YouTube LLC., 901 Cherry Ave., 94066 San Bruno, CA, USA, nachfolgend nur „YouTube“ genannt.</p>
            <p>YouTube ist ein Tochterunternehmen der Google LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.</p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“) <a style={{color:'#999'}} href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active</a> garantiert Google und damit auch das Tochterunternehmen YouTube, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p>Wir nutzen YouTube im Zusammenhang mit der Funktion „Erweiterter Datenschutzmodus“, um Ihnen Videos anzeigen zu können. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Qualitätsverbesserung unseres Internetauftritts. Die Funktion „Erweiterter Datenschutzmodus“ bewirkt laut Angaben von YouTube, dass die nachfolgend noch näher bezeichneten Daten nur dann an den Server von YouTube übermittelt werden, wenn Sie ein Video auch tatsächlich starten.</p>
            <p>Ohne diesen „Erweiterten Datenschutz“ wird eine Verbindung zum Server von YouTube in den USA hergestellt, sobald Sie eine unserer Internetseiten, auf der ein YouTube-Video eingebettet ist, aufrufen.</p>
            <p>Diese Verbindung ist erforderlich, um das jeweilige Video auf unserer Internetseite über Ihren Internet-Browser darstellen zu können. Im Zuge dessen wird YouTube zumindest Ihre IP-Adresse, das Datum nebst Uhrzeit sowie die von Ihnen besuchte Internetseite erfassen und verarbeiten. Zudem wird eine Verbindung zu dem Werbenetzwerk „DoubleClick“ von Google hergestellt.</p>
            <p>Sollten Sie gleichzeitig bei YouTube eingeloggt sein, weist YouTube die Verbindungsinformationen Ihrem YouTube-Konto zu. Wenn Sie das verhindern möchten, müssen Sie sich entweder vor dem Besuch unseres Internetauftritts bei YouTube ausloggen oder die entsprechenden Einstellungen in Ihrem YouTube-Benutzerkonto vornehmen.</p>
            <p>Zum Zwecke der Funktionalität sowie zur Analyse des Nutzungsverhaltens speichert YouTube dauerhaft Cookies über Ihren Internet-Browser auf Ihrem Endgerät. Falls Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Speicherung der Cookies durch eine Einstellung in Ihrem Internet-Browsers zu verhindern. Nähere Informationen hierzu finden Sie vorstehend unter „Cookies“.</p>
            <p>Weitergehende Informationen über die Erhebung und Nutzung von Daten sowie Ihre diesbezüglichen Rechte und Schutzmöglichkeiten hält Google in den unter <a style={{color:'#999'}} href="https://policies.google.com/privacy">https://policies.google.com/privacy</a> abrufbaren Datenschutzhinweisen bereit.</p>
            <h3>Google AdWords mit Conversion-Tracking</h3>
            <p>In unserem Internetauftritt setzen wir die Werbe-Komponente Google AdWords und dabei das sog. Conversion-Tracking ein. Es handelt sich hierbei um einen Dienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.</p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“) <a style={{color:'#999'}} href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active</a> garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p>Wir nutzen das Conversion-Tracking zur zielgerichteten Bewerbung unseres Angebots. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.</p>
            <p>Falls Sie auf eine von Google geschaltete Anzeige klicken, speichert das von uns eingesetzte Conversion-Tracking ein Cookie auf Ihrem Endgerät. Diese sog. Conversion-Cookies verlieren mit Ablauf von 30 Tagen ihre Gültigkeit und dienen im Übrigen nicht Ihrer persönlichen Identifikation.</p>
            <p>Sofern das Cookie noch gültig ist und Sie eine bestimmte Seite unseres Internetauftritts besuchen, können sowohl wir als auch Google auswerten, dass Sie auf eine unserer bei Google platzierten Anzeigen geklickt haben und dass Sie anschließend auf unseren Internetauftritt weitergeleitet worden sind.</p>
            <p>Durch die so eingeholten Informationen erstellt Google uns eine Statistik über den Besuch unseres Internetauftritts. Zudem erhalten wir hierdurch Informationen über die Anzahl der Nutzer, die auf unsere Anzeige(n) geklickt haben sowie über die anschließend aufgerufenen Seiten unseres Internetauftritts. Weder wir noch Dritte, die ebenfalls Google-AdWords einsetzten, werden hierdurch allerdings in die Lage versetzt, Sie auf diesem Wege zu identifizieren.</p>
            <p>Durch die entsprechenden Einstellungen Ihres Internet-Browsers können Sie zudem die Installation der Cookies verhindern oder einschränken. Gleichzeitig können Sie bereits gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support.</p>
            <p>Ferner bietet auch Google unter <a style={{color:'#999'}} href="https://services.google.com/sitestats/de.html">https://services.google.com/sitestats/de.html</a>, <a style={{color:'#999'}} href="http://www.google.com/policies/technologies/ads/">http://www.google.com/policies/technologies/ads/</a> und <a style={{color:'#999'}} href="http://www.google.de/policies/privacy/">http://www.google.de/policies/privacy/</a> weitergehende Informationen zu diesem Thema und dabei insbesondere zu den Möglichkeiten der Unterbindung der Datennutzung an.</p>
            <h3>Google AdSense</h3>
            <p>In unserem Internetauftritt setzen wir zur Einbindung von Werbeanzeigen Google AdSense ein. Es handelt sich hierbei um einen Dienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.</p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“) <a style={{color:'#999'}} href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active</a> garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p>Durch Google AdSense werden Cookies sowie sog. Web Beacons über Ihren Internet-Browser auf Ihrem Endgerät gespeichert. Hierdurch ermöglicht uns Google die Analyse der Nutzung unseres Internetauftritts durch Sie. Die so erfassten Informationen werden neben Ihrer IP-Adresse und der Ihnen angezeigten Werbeformate an Google in die USA übertragen und dort gespeichert. Ferner kann Google diese Informationen an Vertragspartner weitergeben. Google erklärt allerdings, dass Ihre IP-Adresse nicht mit anderen Daten von Ihnen zusammengeführt würden.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.</p>
            <p>Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Installation der Cookies durch die entsprechenden Einstellungen in Ihrem Internet-Browser zu verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem Punkt „Cookies“.</p>
            <p>Zudem bietet Google unter <a style={{color:'#999'}} href="https://policies.google.com/privacy">https://policies.google.com/privacy</a> und <a style={{color:'#999'}} href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a> weitere Informationen an und zwar insbesondere zu den Möglichkeiten der Unterbindung der Datennutzung.</p>
            <h3>Google Remarketing oder "Ähnliche Zielgruppen"-Komponente von Google</h3>
            <p>In unserem Internetauftritt setzen wir die Remarketing- oder „Ähnliche Zielgruppen“-Funktion ein. Es handelt sich hierbei um einen Dienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.</p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“) <a style={{color:'#999'}} href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI[&amp;]status=Active</a> garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p>Wir nutzen diese Funktion, um interessenbezogene, personalisierte Werbung auf Internetseiten Dritter, die ebenfalls an dem Werbe-Netzwerk von Google teilnehmen, zu schalten.</p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.</p>
            <p>Damit dieser Werbe-Dienst ermöglicht werden kann, speichert Google während Ihres Besuchs unseres Internetauftritts über Ihren Internet-Browser ein Cookie mit einer Zahlenfolge auf Ihrem Endgerät. Dieses Cookie erfasst in anonymisierter Form sowohl Ihren Besuch als auch die Nutzung unseres Internetauftritts. Personenbezogene Daten wird dabei allerdings nicht weitergegeben. Sofern Sie anschließend den Internetauftritt eines Dritten besuchen, der seinerseits ebenfalls das Werbe-Netzwerk von Google nutzt, werden womöglich Werbeeinblendungen erscheinen, die einen Bezug zu unserem Internetauftritt bzw. zu unseren dortigen Angeboten aufweisen.</p>
            <p>Zur dauerhaften Deaktivierung dieser Funktion bietet Google für die gängigsten Internet-Browser über <a style={{color:'#999'}} href="https://www.google.com/settings/ads/plugin">https://www.google.com/settings/ads/plugin</a> ein Browser-Plugin an.</p>
            <p>Ebenfalls kann die Verwendung von Cookies bestimmter Anbieter bspw. über <a style={{color:'#999'}} href="http://www.youronlinechoices.com/uk/your-ad-choices">http://www.youronlinechoices.com/uk/your-ad-choices</a> oder <a style={{color:'#999'}} href="http://www.networkadvertising.org/choices/">http://www.networkadvertising.org/choices/</a> per Opt-out deaktiviert werden.</p>
            <p>Durch das sog. <strong>Cross-Device-Marketing</strong> kann Google Ihr Nutzungsverhalten unter Umständen auch über mehrere Endgeräte hinweg verfolgen, sodass Ihnen womöglich selbst dann interessenbezogene, personalisierte Werbung angezeigt wird, wenn Sie das Endgerät wechseln. Dies setzt allerdings voraus, dass Sie der Verknüpfung Ihrer Browserverläufe mit Ihrem bestehenden Google-Konto zugestimmt haben.</p>
            <p>Google bietet weitergehende Informationen zu Google Remarketing unter <a style={{color:'#999'}} href="http://www.google.com/privacy/ads/">http://www.google.com/privacy/ads/</a> an.</p>
            <h3>Online-Stellenbewerbungen / Veröffentlichung von Stellenanzeigen</h3>
            <p>Wir bieten Ihnen die Möglichkeit an, sich bei uns über unseren Internetauftritt bewerben zu können. Bei diesen digitalen Bewerbungen werden Ihre Bewerber- und Bewerbungsdaten von uns zur Abwicklung des Bewerbungsverfahrens elektronisch erhoben und verarbeitet.</p>
            <p>Rechtsgrundlage für diese Verarbeitung ist § 26 Abs. 1 S. 1 BDSG i.V.m. Art. 88 Abs. 1 DSGVO.</p>
            <p>Sofern nach dem Bewerbungsverfahren ein Arbeitsvertrag geschlossen wird, speichern wir Ihre bei der Bewerbung übermittelten Daten in Ihrer Personalakte zum Zwecke des üblichen Organisations- und Verwaltungsprozesses – dies natürlich unter Beachtung der weitergehenden rechtlichen Verpflichtungen.</p>
            <p>Rechtsgrundlage für diese Verarbeitung ist ebenfalls § 26 Abs. 1 S. 1 BDSG i.V.m. Art. 88 Abs. 1 DSGVO.</p>
            <p>Bei der Zurückweisung einer Bewerbung löschen wir die uns übermittelten Daten automatisch zwei Monate nach der Bekanntgabe der Zurückweisung. Die Löschung erfolgt jedoch nicht, wenn die Daten aufgrund gesetzlicher Bestimmungen, bspw. wegen der Beweispflichten nach dem AGG, eine längere Speicherung von bis zu vier Monaten oder bis zum Abschluss eines gerichtlichen Verfahrens erfordern.</p>
            <p>Rechtsgrundlage ist in diesem Fall Art. 6 Abs. 1 lit. f) DSGVO und § 24 Abs. 1 Nr. 2 BDSG. Unser berechtigtes Interesse liegt in der Rechtsverteidigung bzw. -durchsetzung.</p>
            <p>Sofern Sie ausdrücklich in eine längere Speicherung Ihrer Daten einwilligen, bspw. für Ihre Aufnahme in eine Bewerber- oder Interessentendatenbank, werden die Daten aufgrund Ihrer Einwilligung weiterverarbeitet. Rechtsgrundlage ist dann Art. 6 Abs. 1 lit. a) DSGVO. Ihre Einwilligung können Sie aber natürlich jederzeit nach Art. 7 Abs. 3 DSGVO durch Erklärung uns gegenüber mit Wirkung für die Zukunft widerrufen.</p>
            <p><a target={"_blank"} style={{color:'#999'}} href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html">Muster-Datenschutzerklärung</a> der <a target={"_blank"} style={{color:'#999'}} href="https://www.ratgeberrecht.eu/">Anwaltskanzlei Weiß &amp; Partner</a></p>
            <p></p><h3>Einsatz von Social-Media-Plugins</h3>
            <p>(1) Wir setzen derzeit folgende Social-Media-Plug-ins ein: [Google+ Facebook, Google+, Twitter, Xing, T3N, LinkedIn, Flattr,. Wir nutzen dabei die sog. Zwei-Klick-Lösung. Das heißt, wenn Sie unsere Seite besuchen, werden zunächst grundsätzlich keine personenbezogenen Daten an die Anbieter der Plug-ins weitergegeben. Den Anbieter des Plug-ins erkennen Sie über die Markierung auf dem Kasten über seinen Anfangsbuchstaben oder das Logo. Wir eröffnen Ihnen die Möglichkeit, über den Button direkt mit dem Anbieter des Plug-ins zu kommunizieren. Nur wenn Sie auf das markierte Feld klicken und es dadurch aktivieren, erhält der Plug-in-Anbieter die Information, dass Sie die entsprechende Website unseres Online-Angebots aufgerufen haben. Zudem werden die unter § 3 dieser Erklärung genannten Daten übermittelt. Im Fall von Facebook und Xing wird nach Angaben der jeweiligen Anbieter in Deutschland die IP-Adresse sofort nach Erhebung anonymisiert. Durch die Aktivierung des Plug-ins werden also personenbezogene Daten von Ihnen an den jeweiligen Plug-in-Anbieter übermittelt und dort (bei US-amerikanischen Anbietern in den USA) gespeichert. Da der Plug-in-Anbieter die Datenerhebung insbesondere über Cookies vornimmt, empfehlen wir Ihnen, vor dem Klick auf den ausgegrauten Kasten über die Sicherheitseinstellungen Ihres Browsers alle Cookies zu löschen.</p>
            <p>(2) Wir haben weder Einfluss auf die erhobenen Daten und Datenverarbeitungsvorgänge, noch sind uns der volle Umfang der Datenerhebung, die Zwecke der Verarbeitung, die Speicherfristen bekannt. Auch zur Löschung der erhobenen Daten durch den Plug-in-Anbieter liegen uns keine Informationen vor.</p>
            <p>(3) Der Plug-in-Anbieter speichert die über Sie erhobenen Daten als Nutzungsprofile und nutzt diese für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (auch für nicht eingeloggte Nutzer) zur Darstellung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht gegen die Bildung dieser Nutzerprofile zu, wobei Sie sich zur Ausübung dessen an den jeweiligen Plug-in-Anbieter wenden müssen. Über die Plug-ins bietet wir Ihnen die Möglichkeit, mit den sozialen Netzwerken und anderen Nutzern zu interagieren, so dass wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten können. Rechtsgrundlage für die Nutzung der Plug-ins ist Art. <a style={{color:'#999'}} href="https://beck-online.beck.de/?typ=reference[&amp;]y=100[&amp;]a=6[&amp;]g=EWG_DSGVO">6</a> Abs. <a style={{color:'#999'}} href="https://beck-online.beck.de/?typ=reference[&amp;]y=100[&amp;]a=6[&amp;]g=EWG_DSGVO[&amp;]x=1">1</a> S. 1 lit. f DSGVO.</p>
            <p>(4) Die Datenweitergabe erfolgt unabhängig davon, ob Sie ein Konto bei dem Plug-in-Anbieter besitzen und dort eingeloggt sind. Wenn Sie bei dem Plug-in-Anbieter eingeloggt sind, werden Ihre bei uns erhobenen Daten direkt Ihrem beim Plug-in-Anbieter bestehenden Konto zugeordnet. Wenn Sie den aktivierten Button betätigen und z.?B. die Seite verlinken, speichert der Plug-in-Anbieter auch diese Information in Ihrem Nutzerkonto und teilt sie Ihren Kontakten öffentlich mit. Wir empfehlen Ihnen, sich nach Nutzung eines sozialen Netzwerks regelmäßig auszuloggen, insbesondere jedoch vor Aktivierung des Buttons, da Sie so eine Zuordnung zu Ihrem Profil bei dem Plug-in-Anbieter vermeiden können.</p>
            <p>(5) Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch den Plug-in-Anbieter erhalten Sie in den im Folgenden mitgeteilten Datenschutzerklärungen dieser Anbieter. Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre.</p>
            <p>(6) Adressen der jeweiligen Plug-in-Anbieter und URL mit deren Datenschutzhinweisen:</p>
            <ol>
            <li>Facebook Inc., 1601 S California Ave, Palo Alto, California 94304, USA; http://www.facebook.com/policy.php; weitere Informationen zur Datenerhebung: http://www.facebook.com/help/186325668085084, http://www.facebook.com/about/privacy/your-info-on-other#applications sowie http://www.facebook.com/about/privacy/your-info#everyoneinfo. Facebook hat sich dem EU-US-Privacy-Shield unterworfen, <a style={{color:'#999'}} href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.</li>
            <li>Google Inc., 1600 Amphitheater Parkway, Mountainview, California 94043, USA; https://www.google.com/policies/privacy/partners/?hl=de. Google hat sich dem EU-US-Privacy-Shield unterworfen, <a style={{color:'#999'}} href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.</li>
            <li>Twitter, Inc., 1355 Market St, Suite 900, San Francisco, California 94103, USA; https://twitter.com/privacy. Twitter hat sich dem EU-US-Privacy-Shield unterworfen, <a style={{color:'#999'}} href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.</li>
            <li>Xing AG, Gänsemarkt 43, 20354 Hamburg, DE; <a style={{color:'#999'}} href="http://www.xing.com/privacy">http://www.xing.com/privacy</a>.</li>
            <li>T3N, yeebase media GmbH, Kriegerstr. 40, 30161 Hannover, Deutschland; <a style={{color:'#999'}} href="https://t3n.de/store/page/datenschutz">https://t3n.de/store/page/datenschutz</a>.</li>
            <li>LinkedIn Corporation, 2029 Stierlin Court, Mountain View, California 94043, USA; http://www.linkedin.com/legal/privacy-policy. LinkedIn hat sich dem EU-US-Privacy-Shield unterworfen, <a style={{color:'#999'}} href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.</li>
            <li>Flattr Network Ltd. mit Sitz in 2?nd Floor, White bear yard 114A, Clerkenwell Road, London, Middlesex, England, EC1R 5DF, Großbritannien; https://flattr. com/privacy.]</li>
            </ol>
            <h3>Auftragsverarbeiter</h3>
            <p>Wir bedienen uns externen Dienstleister (Auftragsverarbeiter) z. B. für den Versand von Waren, Newsletter oder Zahlungsabwicklungen. Mit dem Dienstleister wurde eine separate Auftragsdatenverarbeitung geschlossen, um den Schutz Ihrer personenbezogenen Daten zu gewährleisten.</p>
                            	
					</article>
			</main>
    );
  }
}

export default Datenschutz;
