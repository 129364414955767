import React, { Component } from 'react';
import { isMobileOnly, isBrowser, isTablet, CustomView } from 'react-device-detect';

class ECommerce extends Component {

    componentWillReceiveProps(nextProps) {
    }

    render() {
        return (
            <div id='otherSubview' className="container-fluid" >
                
                    <div className="row">

                        <div className="col-xxl-6">
                            <div className="container-fluid" >
                                <div className="row">

                                    <div className="col">
                                        <h2>Ihr Online-Shop</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <CustomView condition={isBrowser || isTablet} >
                                            <article>
                                                Viele Autohäuser nutzen bereits Verkaufsplattformen wie ebay für den Verkauf von Autoteilen oder von Dienstleistungen. Viele entscheiden sich gleich für einen eigenen Webshop. Lohnt sich das auch für Sie?<br /> <br />
                                                Gern beraten wir Sie, welche Möglichkeiten Sie haben, mit welchem Aufwand und mit welchen Umsätzen Sie rechnen können. Wir richten Ihnen den Shop komplett ein und erklären Ihnen alle Funktionen. Sie werden sehen, 
                                                dass alles viel einfacher ist, als Sie es sich am Anfang vorgestellt haben. <br /> <br />
                                                Machen Sie es sich leicht und starten Sie mit einem kleinen Shop mit wenigen Angeboten. Dann verpassen Sie keine Chance und sehen selbst, wie sich alles entwickelt. Selbstverständlich unterstützen wir Sie jederzeit – 
                                                wenn Sie Fragen haben, wenn es Software-Updates gibt oder wenn Sie die Angebote im Shop erweitern wollen. Mit uns haben Sie immer einen starken Partner an Ihrer Seite, der für Ihr Unternehmen dieselben Ziele hat wie Sie!
                                            </article>
                                        </CustomView>
                                        <CustomView condition={isMobileOnly} >
                                            <article>
                                                Viele Autohäuser verkaufen Autoteile oder Dienstleistungen online. Lohnt sich das auch für Sie? Erfahren Sie, welche Möglichkeiten Sie haben, welchen Aufwand und welche Umsätze Sie erzielen können. 
                                                Mit unserer Unterstützung ist alles viel einfacher, als Sie es sich jetzt vielleicht vorstellen. Starten Sie zum Beispiel mit einem kleinen Shop mit wenigen Angeboten und sehen selbst, wie es sich entwickelt. 
                                                Wir stehen Ihnen zuverlässig zur Seite!
                                            </article>
                                        </CustomView>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-6 d-none d-xxl-block" style={{backgroundImage:`url(${require('../../../media/img/online_shop-min.png')}), none`, backgroundRepeat:'no-repeat', backgroundSize:'cover contain', backgroundPosition: '-50% 30%'}}>
                        </div>
                    
                    </div>
                </div>
        );
    }
}

export default ECommerce;
