import React, { Component } from 'react';
import { isMobileOnly, isBrowser, isTablet, CustomView } from 'react-device-detect';
import {withRouter, Link} from 'react-router-dom';

import AutohausMarketing  from './subviews/AutohausMarketing';
import Website  from './subviews/Website';
import GoogleCampaign  from './subviews/GoogleCampaign';
import ECommerce  from './subviews/ECommerce';
import Videos  from './subviews/Videos';
import OnlineMarketing  from './subviews/OnlineMarketing';

import './About.scss';

class About extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeView: 0,
            initalUpdate: false,
            isPageActive: false,
            subviews: [<AutohausMarketing/>, <Website/>, <GoogleCampaign />, <OnlineMarketing />, <ECommerce />, <Videos />],
            links: ['/about/', '/about/website', '/about/google-ads', '/about/online-marketing', '/about/online-shop', '/about/video-marketing'],
            linkTitles: ['Autohaus-Marketing', 'Website', 'Google-Werbung', 'Online-Marketing', 'Online-Shop', 'Video Marketing']
        };

        this.changeSubview = this.changeSubview.bind(this);
        this.manageRoutes = this.manageRoutes.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.manageRoutes(nextProps.location.pathname);
    }

    componentDidMount() {
        this.manageRoutes(this.props.location.pathname);
    }

    changeSubview(index) {
        this.setState({activeView:index});
    }

    manageRoutes(route) {
        if (route.includes('/about')) {
            this.changeSubview(this.state.links.indexOf(route));
        }
    }

    render() {
        return (
            <div id="about" className="container-fluid light-theme ">
            
                <div className={`row ${(isMobileOnly) ? 'isMobile' : 'isDesktop'}`}>
                    <div id="about-side" className="col-md">

                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1>
                                        Über Uns
                                    </h1>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col">
                                    <CustomView condition={isBrowser || isTablet} >
                                        <p>
                                            5W-50 ist eine Berliner <b>Marketingagentur</b>, die sich auf die <b>Bedürfnisse von Autohäusern</b> spezialisiert hat. Profitieren Sie besonders von unseren Erfahrungen im <b>Online-Marketing</b>. 
                                            Sichern Sie Ihre geschäftliche Zukunft – mit uns. Denn auch Ihre <b>Kunden</b> kaufen online und zukünftige <b>Mitarbeiter</b> informieren sich im Web!
                                        </p>
                                    </CustomView>
                                    <CustomView condition={isMobileOnly} >
                                        <p>
                                            5W-50 hat sich auf die <b>Bedürfnisse von Autohäusern</b> spezialisiert. Mit unseren Erfahrungen im <b>Online-Marketing</b> können Sie Ihre geschäftliche Zukunft sichern. 
                                            Denn auch Ihre <b>Kunden</b> kaufen online und <b>Fachkräfte</b> informieren sich im Web über potenzielle Arbeitgeber!
                                        </p>
                                    </CustomView>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="about-body" className={`col-md ${(isMobileOnly) ? 'isMobile' : ''}`}>


                        <CustomView condition={isBrowser || isTablet} >
                            <div id="about-content-container" className="container-fluid ">
                                
                                <div id="about-nav" className="row align-items-end">
                                    <div className="col">
                                        <nav className="nav">
                                            {this.state.links.map((e,i) => (
                                                <Link key={i} to={e}>
                                                    <button  className={`nav-link btn w-secondary ${(this.state.activeView === i) ? "active" : ""}`} >
                                                        <h3>{this.state.linkTitles[i]}</h3>
                                                    </button>
                                                </Link>
                                            ))}
                                        </nav>
                                    </div>
                                </div>

                                <div id="about-content" className="row">
                                    <div className="col">
                                        {this.state.subviews[this.state.activeView]}
                                    </div>
                                </div>
                            </div>
                        </CustomView>




                        <CustomView condition={isMobileOnly}>
                        
                            <div className="accordion" id="accordionAbout">

                                {this.state.subviews.map((e,i) => (
                                        
                                    <div key={i} className="card">
                                        <div className="card-header" id={`heading-${i}`}>
                                            <h5 className="mb-0">
                                                <button className={`btn btn-link ${(i === 0) ? '' : 'collapsed' }`} type="button" data-toggle="collapse" data-target={`#collapse-${i}`} aria-expanded={(i === 0) ? 'true' : 'false' } aria-controls={`collapse-${i}`}>
                                                    <h3>
                                                        {this.state.linkTitles[i]}
                                                    </h3>
                                                </button>
                                            </h5>
                                            </div>
            
                                            <div id={`collapse-${i}`} className={`collapse ${(i === 0) ? 'show' : '' }`} aria-labelledby={`heading-${i}`} data-parent="#accordionAbout">
                                            <div className="card-body">
                                                {this.state.subviews[i]}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        
                        </CustomView>



                    </div>
                </div>
                
            </div>
        );
    }
}

export default withRouter(About);