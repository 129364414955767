import React, { Component } from 'react';
import Slider from "react-slick";
import { isMobileOnly, isBrowser, isTablet, CustomView } from 'react-device-detect';
import Image from 'react-image-webp'

import './References.scss';

class References extends Component {
    constructor(props) {    
        super(props);
        this.state = {
            images: ["02_Referenz_5w-50", "01_Referenz_5w-50", "03_Referenz_5w-50"]
        };
    }

  render() {
    var settings = {
        dots: true,
        arrows: false,
        adaptiveHeight:true,
        lazyLoad: 'ondemand',
        autoplay: true,
        autoplaySpeed: 8000
    };
    
    const mobileURL = ((isMobileOnly) ? "_mobile" : "");
    return (
        <div id='references' className="container-fluid light-theme ">
            <div id='references-body' className="row ">

                <div className="container-fluid" style={{padding:0}}>
                    <Slider {...settings}>
                    {this.state.images.map((e, i) => (
                        <Image style={{height:'50vh'}} key={i}
                            className="slick-item-image"
                            src={require('../../media/img/' + e + mobileURL + "-min-min.jpg")}
                            webp={require('../../media/img/' + e + mobileURL + "-min.webp")} />
                    ))}
                    </Slider>
                </div>
                    
            </div>
            <div id='references-side' className="row align-items-center ">
            
                    <div className="container-fluid">
                        <div className="row align-items-center ">
                            <div className="col">
                                <h1>
                                    Wir sind stolz auf unsere Referenzen
                                </h1>
                            </div>

                            <div className="col">
                                <CustomView condition={isBrowser || isTablet} >
                                    <p>
                                        Ein vertrauensvolles Verhältnis zu Ihnen als Kundin oder Kunde ist die Basis unserer Arbeit. Sie sollen immer wissen, was wir tun und warum wir das tun. 
                                        Darum sind Ihr Feedback und Ihre Zufriedenheit in jeder Phase unserer gemeinsamen Projekte von großer Bedeutung.
                                    </p>
                                </CustomView>
                                <CustomView condition={isMobileOnly} >
                                    <p>
                                        Ein vertrauensvolles Verhältnis zu Ihnen als Kundin oder Kunde ist die Basis unserer Arbeit. Sie sollen immer wissen, was wir tun und warum wir das tun. 
                                        Darum sind Ihr Feedback und Ihre Zufriedenheit in jeder Phase unserer gemeinsamen Projekte von großer Bedeutung.
                                    </p>
                                </CustomView>
                            </div>

                        </div>
                    </div>

            </div>
        </div>
    );
  }
}

export default References;
