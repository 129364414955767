import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { isMobileOnly, isBrowser, isTablet, CustomView } from 'react-device-detect';

//import '../../styles/subviews/website.css'

class Website extends Component {
    componentWillReceiveProps(nextProps) {
    }

    render() {
        return (
            <div id='otherSubview' className="container-fluid " >
                
                    <div className="row">
                        <div className="col-xxl-6">
                            <div className="container-fluid" >
                                <div className="row">
                                    <div className="col">
                                        <h2>Website</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">

                                        <CustomView condition={isBrowser || isTablet} >
                                            <article>
                                                Eine <b>nutzerfreundliche Website</b> bedeutet für Kaufinteressenten <b>guter Service von Anfang an</b>. Lassen Sie andere teure, lästige Werbung machen. Geben Sie den Kunden von heute genau das, was sie wollen! Fragen Sie uns nach einem Angebot für eine <b>moderne Website</b>. <br /> <br />Das können Sie sicher bestätigen: Bevor Interessenten Ihr Autohaus betreten, waren die meisten vorher im Internet. Dort suchen sie zunächst nach <b>Informationen</b> und dann erst gezielt nach Produkten und Preisen.
                                                Das gilt besonders für so komplexe Produkte wie Autos. <Link to= '/about/online-marketing'>Online-Marketing</Link> zielt darauf ab, den Kaufinteressenten möglichst früh auf die eigene Marke aufmerksam zu machen und an die eigenen Produkte zu binden. <br /><br />
                                                <b>Online-Marketing</b> beginnt mit einer <b>modernen Website</b>, die auf jedem internetfähigen Gerät korrekt angezeigt wird. Eine einfache Nutzerführung und die leichte Kontaktaufnahme sind weitere Kriterien. Zeitgemäße Programmierung und überzeugende 
                                                Texte bringen Ihren Webauftritt auch <b>bei Google nach vorn</b>. <br /> <br /><b>Übrigens</b>: Eine Website, die bei Google gut gefunden wird, wird auch von <b>Fachkräften</b> gut gefunden. Überzeugen Sie auch <b>zukünftige Mitarbeiter</b> mit einem 
                                                professionellen Webauftritt! Rufen Sie uns an oder schreiben Sie eine <a href='mailto:info@5w-50.de'>E-Mail</a>.
                                            </article>
                                        </CustomView>
                                        <CustomView condition={isMobileOnly} >
                                            <article>
                                                Unser Online-Marketing zielt darauf ab, den Kaufinteressenten möglichst früh auf Ihre Marke aufmerksam zu machen und an Ihre Produkte zu binden. Der erste Schritt ist eine <b>moderne Website</b>, die fürs Smartphone optimiert ist – mit einfacher Nutzerführung 
                                                und leichter Kontaktaufnahme. Suchmaschinenoptimierte Programmierung und Texte bringen Ihren Webauftritt auch <b>bei Google nach vorn</b>. Rufen Sie uns an oder schreiben Sie eine <a href='mailto:info@5w-50.de'>E-Mail</a>. 
                                                <br /><br />
                                                Übrigens: <b>Zukünftige Mitarbeiter</b> überzeugen Sie besser mit einem professionellen Webauftritt! 
                                            </article>
                                        </CustomView>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-6 d-none d-xxl-block" style={{backgroundImage:`url(${require('../../../media/img/website-min.png')}), none`, backgroundRepeat:'no-repeat', backgroundSize:'contain cover', backgroundPosition: '-30% 30%'}}>
                        </div>

                    </div>
                </div>
        );
    }
}

export default Website;
