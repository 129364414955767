
import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import { isMobileOnly, isBrowser, isTablet, CustomView } from 'react-device-detect';

class OnlineMarketing extends Component {

    componentWillReceiveProps(nextProps) {
    }

    render() {
        return (
            <div id='otherSubview' className="container-fluid" >
                
                    <div className="row">

                        <div className="col-xxl-6">
                            <div className="container-fluid" >
                                <div className="row">
                                    <div className="col">
                                        <h2>Online-Marketing</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">

                                        <CustomView condition={isBrowser || isTablet} >
                                            <article>
                                                Unternehmen, die heute im Internet nicht rundum professionell aufgestellt sind, werden morgen vielleicht nicht mehr existieren.
                                                Wir sind auf das <b>Online-Marketing für Autohäuser</b> spezialisiert. Hier kennen wir die Kunden, die Produkte, die Herausforderungen. Mit unserer Expertise können Sie beruhigt in die Zukunft blicken.<br /> <br />
                                                Aus unserer Arbeit der letzten Jahre wissen wir: Viele Ihrer <b>Mitbewerber</b> stehen noch ganz am Anfang, was die <b>Kundengewinnung im Internet</b> angeht. <i>Mobile.de</i> und <i>AutoScout24 </i> 
                                                zu nutzen – das ist nur die Spitze des Eisberges! Wenn Sie die Webseiten Ihrer Mitbewerber besuchen, werden Sie oft entdecken, dass der Servicegedanke hier noch nicht angekommen ist: Da fehlen zum Beispiel 
                                                die Öffnungszeiten oder sie sind veraltet, die Struktur der Seiten ist umständlich und die grafische Darstellung … Welche Grafik?!?<br /> <br />Nutzen Sie alle Chancen, die das Web Ihnen heute bietet:<br /><br />
                                                <li>Kunden binden und gewinnen mit einer modernen <Link to='/about/website'>Website</Link></li>
                                                <li>mehr verkaufen mit effizienter <Link to='/about/google-ads'>Google-Werbung</Link></li>
                                                <li>mehr Umsatz mit einem eigenen <Link to='/about/online-shop'>Online-Shop</Link></li>
                                                <li><Link to='/about/video-marketing'>Video-Marketing</Link> für mehr Bekanntheit, mehr Kundenbindung …</li><br />
                                                Wir beraten Sie objektiv und sprechen Ihre Sprache. 
                                                Rufen Sie einfach an oder <a href='mailto:info@5w-50.de'>schreiben</a> Sie.
                                            </article>
                                        </CustomView>
                                        <CustomView condition={isMobileOnly} >
                                            <article>
                                                Wir sind auf Online-Marketing für <b>Autohäuser</b> spezialisiert. Auf den Webseiten Ihrer Mitbewerber werden Sie entdecken, dass der Servicegedanke dort oft noch nicht angekommen ist. 
                                                Nutzen Sie diese Chancen auf dem Weg zum Online-Platzhirsch:
                                                <br /><br />
                                                <li>eine moderne <u>Website</u></li>
                                                <li>effiziente <u>Google-Werbung</u></li>
                                                <li>ein eigener <u>Online-Shop</u></li>
                                                <li>wirksames <u>Video-Marketing</u></li>
                                                <br /><br />
                                                Bauen Sie auf Wachstum und rufen Sie einfach an oder <a href='mailto:info@5w-50.de'>schreiben</a> Sie. 
                                                Sichern Sie Ihre Zukunft jetzt – mit uns!
                                            </article>
                                        </CustomView>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-6 d-none d-xxl-block" style={{backgroundImage:`url(${require('../../../media/img/target-min.png')}), none`, backgroundRepeat:'no-repeat', backgroundSize:'contain cover', backgroundPosition: '0% 30%'}}>
                        </div>
                    
                    </div>
                </div>
        );
    }
}

export default OnlineMarketing;
