import React, { Component } from 'react';
import { isMobileOnly, isBrowser, isTablet, CustomView } from 'react-device-detect';
import {Link} from 'react-router-dom'

class AutohausMarketing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: [{
                title: "+400%",
                description: "Mehr Homepage-Besucher durch Suchmaschinen-optimierung",
                mobile:"Mehr Homepage-Besucher"
            }, {
                title: "+134%",
                description: "Mehr Anfragen per E-Mail durch verbesserte Technik",
                mobile: 'Mehr Anfragen per E-Mail'
            }, {
                title: "8.878",
                description: "Anfragen generiert<br /><span>(2,2 Mio. EUR Ertrag/anno)</span>",
                mobile: "Anfragen generiert<br /><span>(2,2 Mio. EUR Ertrag/anno)</span>"
            }, {
                title: "+500%",
                description: "Mehr Informationen durch optimierte Benutzerfreundlichkeit",
                mobile: "Mehr Informationen durch optimierte Benutzerfreundlichkeit"
            }, {
                title: "+47%",
                description: "Spitzen-Conversion bei einem Audi Zentrum",
                mobile: "Spitzen-Conversion bei einem Audi Zentrum"
            }]
        };
    }

    componentWillReceiveProps(nextProps) {
    }

    render() {
        return (
            <div className="container-fluid" >
                <div className="row">

                    <div className="col-xxl-6">
                        <div id="marketing" className="container-fluid" >
                            <div className="row">
                                <div className="col">
                                    <h2>Ihr Autohaus-Marketing</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">

                                    

                                    <CustomView condition={isBrowser || isTablet} >
                                        <article>
                                            SEO, SEM, Conversion, Leads und Landingpages – was Ihnen heute noch wie ein undurchdringlicher Dschungel vorkommt, ist in Wahrheit ein <b>ganzes Paket von Chancen</b>! 
                                            Können Sie sich Ihr Leben noch ohne Computer vorstellen? Wahrscheinlich nicht. Genauso selbstverständlich wird es bald für Sie sein, mit uns über Ihr <Link to= '/about/online-marketing'>Online-Marketing</Link> zu reden. 
                                            Wir erklären Ihnen von Anfang an leicht verständlich den Nutzen der verschiedenen Maßnahmen. Und wie wir gemeinsam für Ihr Budget das Beste herausholen.
                                        </article>
                                    </CustomView>
                                    <CustomView condition={isMobileOnly} >
                                        <article>
                                        SEO, SEM und Landingpages – das sind Ihre <b>Chancen von heute</b>! Von uns erfahren Sie den Nutzen der verschiedenen Maßnahmen. Und wie wir für Ihr Budget das Beste herausholen!
                                        </article>
                                    </CustomView>
                                </div>
                                <div id="marketing-info" className="container">
                                    <div className="row">
                                        
                                        {this.state.info.map((e, i) => (
                                            <div key={i} className="col-xs-4">
                                                <header>{e.title}</header>
                                                <CustomView condition={isBrowser || isTablet} >
                                                    <article dangerouslySetInnerHTML={{__html:e.description}} />
                                                </CustomView>
                                                <CustomView condition={isMobileOnly} >
                                                    <article dangerouslySetInnerHTML={{__html:e.mobile}} />
                                                </CustomView>
                                            </div>
                                        ))}
                                        
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-6 d-none d-xxl-block" style={{backgroundImage:`url(${require('../../../media/img/ihr_autohaus-min-min.png')}), none`, backgroundRepeat:'no-repeat', backgroundSize:'cover contain', backgroundPosition: '-30% 30%'}}>
                    </div>
                
                </div>
            </div>
        );
    }
}

export default AutohausMarketing;
