
import React, { Component } from 'react';
import { isMobileOnly, isBrowser, isTablet, CustomView } from 'react-device-detect';

class Videos extends Component {
    componentWillReceiveProps(nextProps) {
    }

    render() {
        return (
            <div id='otherSubview' className="container-fluid" >
                
                    <div className="row">

                        <div className="col-xxl-6">
                            <div className="container-fluid" >
                                <div className="row">
                                    <div className="col">
                                        <h2>Video-Marketing</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        

                                        <CustomView condition={isBrowser || isTablet} >
                                            <article>
                                                Menschen lieben Geschichten. Mit einer guten Geschichte zielen Sie genau ins Herz Ihrer Kunden. Oder anders gesagt: 
                                                Sie wecken die Emotionen, die beim Kunden den Wunsch hervorrufen, sich mit Ihrer Marke zu verbinden oder Ihr Produkt zu besitzen.<br /><br />
                                                Mit einem Video erzählen Sie solche Geschichten – mehrere Sinne berührend, leicht konsumierbar, gern geteilt. Videos bringen Ihre 
                                                Botschaften auf den Punkt und können vielseitig eingesetzt werden: <br /><br />
                                                
                                                <li>zur Vorstellung Ihres Unternehmens</li>
                                                <li>zur Marken- und Produkteinführung</li>
                                                <li>in der Pressearbeit</li>
                                                <li>bei Präsentationen</li>
                                                <li>für Tutorials</li>
                                                <li>in der Werbung</li>
                                                <li>auf Ihrer Website, im Newsletter, bei YouTube …</li><br />
                                                
                                                Gute Videos erhöhen Ihre Bekanntheit, stärken die Kundenbindung, wirken überzeugend, und stärken Ihre Position in den Suchmaschinen. 
                                                Lassen Sie sich von uns zu Ihrem Video-Marketing beraten!
                                            </article>
                                        </CustomView>
                                        <CustomView condition={isMobileOnly} >
                                            <article>
                                                Menschen lieben Geschichten. Videos erzählen Geschichten, berühren die Sinne, sind leicht konsumierbar und werden gern geteilt. Nutzen Sie Videos
                                                <br /><br />
                                                <li>zur Unternehmensvorstellung</li>
                                                <li>zur Marken- und Produkteinführung</li>
                                                <li>in der Werbung und der Pressearbeit</li>
                                                <li>bei Präsentationen und für Tutorials</li>
                                                <li>auf Ihrer Website, im Newsletter, bei YouTube …</li>
                                                <br /><br />
                                                Steigern Sie Ihre Bekanntheit, stärken Sie die Kundenbindung und Ihre Position in den Suchmaschinen. Lassen Sie sich von uns zu Ihrem Video-Marketing beraten!
                                            </article>
                                        </CustomView>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-6 d-none d-xxl-block" style={{backgroundImage:`url(${require('../../../media/img/video-min.png')}), none`, backgroundRepeat:'no-repeat', backgroundSize:'contain cover', backgroundPosition: '-30% 30%'}}>
                        </div>
                    
                    </div>
                </div>
        );
    }
}

export default Videos;
