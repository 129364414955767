import React, { Component } from 'react';
import Slider from "react-slick";
import { isMobileOnly, isBrowser, isTablet, CustomView } from 'react-device-detect';

import './Feedback.scss';

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: [{
                img: "Sascha_Schubert.jpg",
                title: "Bundesverband Deutsche Startups e.V.",
                description: "Seit über sechs Jahren sind wir im intensiven \n" +
                    "Gedankenaustausch mit dem Experten Olaf Dicker \n" +
                    "in der Automobilbranche und insbesondere der\n" +
                    "Online-Welt des Autohandels. Er erkennt rechtzeitig\n" +
                    "Trends und geht den Hypothesen auf die Spur und\n" +
                    "ist sehr umsetzungsschnell.",
                name: "Sascha Schubert",
                logo: "bundesverband_startups_deutschland_logo.gif",
                logo_link: "https://www.deutschestartups.org/",
                company: "Bundesverband Deutsche Startups e.V."
            }]
        };
    }

    componentWillReceiveProps(nextProps) {
    }

    render() {
        var settings = {
            dots: true,
            arrows:false,
            autoplay: true,
            customPaging: function(i) {
                return (
                    <a href={`#${i}`}>
                        <div style={{height:"40px", width:"40px", paddingTop:"14px"}}>
                            <div style={{height:"3px", width:"16px", backgroundColor:"#fff"}} />
                        </div>
                    </a>
                );
            }
        };

        return (
            <div id="feedback" className="container-fluid light-theme">
                <div className="row">
                    <div id="feedback-side" className='col'>

                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h1>
                                        DAS SAGEN KUNDEN ÜBER UNS
                                    </h1>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col">
                                    <CustomView condition={isBrowser || isTablet} >
                                        <p>
                                            Ein vertrauensvolles Verhältnis zu Ihnen als Kundin oder Kunde ist die Basis unserer Arbeit.
                                            Sie sollen immer wissen, was wir tun und warum wir das tun.
                                            Darum sind Ihr Feedback und Ihre Zufriedenheit in jeder Phase unserer gemeinsamen Projekte von großer Bedeutung.
                                        </p>
                                    </CustomView>
                                    <CustomView condition={isMobileOnly} >
                                        <p>
                                            Ein vertrauensvolles Verhältnis zu Ihnen ist die Basis unserer Arbeit. Sie wissen, was wir tun und warum.
                                            Ihr Feedback und Ihre Zufriedenheit in jeder Phase unserer Projekte bedeuten uns viel.
                                        </p>
                                    </CustomView>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div id="feedback-body" className='col'>
                        <div className="container-fluid" style={{padding:'0px'}}>
                        <Slider {...settings}>
                                {this.state.info.map((e, i) => (
                                    <div key={i}>

                                        <img className="slick-item-image center" src={require('../../media/img/'+e.img)} alt='Referenzen' />
                                        <div className={`slick-item-info${(i === 1) ? ' position-right' : ''}`} >
                                            <h2>{e.title}</h2>
                                            <article>{e.description}<a href={e.logo_link} target='_blank'><img className="logo-img" src={require('../../media/img/'+e.logo)} alt='' /></a></article>
                                            <p>{e.name}</p>
                                            <p>{e.company}</p>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Feedback;
