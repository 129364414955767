import React, { Component } from 'react';
import Image from 'react-image-webp'

import './Homepage.scss'; 


class Homepage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showButton: false
        }
    }

    componentWillReceiveProps(nextProps) {
    }

    componentDidMount() {
        setTimeout(function () {
            this.setState({showButton:true});
          }.bind(this), 4000);
    }

    render() {
        return (
            <React.Fragment>
                <div id="homepage" className="container-fluid dark-theme">
                    <Image 
                        className="homepage-background-image"
                        src={require('../../media/img/laptop-small-min-min.png')} 
                        webp={require('../../media/img/laptop-small-min.webp')} />

                    <div id="homepage-header" className="row align-items-center">
                        <div className="col-6">
                            <Image 
                                src={require('../../media/img/5W-50-logo-white-min.png')} 
                                webp={require('../../media/img/5W-50-logo-white-min.webp')} />
                        </div>
                        <div className="col-6 d-none d-xs-block" style={{textAlign:"right"}}>
                            +49 30 9155 8408
                        </div>
                        <div className="col-6 d-block d-xs-none" style={{textAlign:"right"}}>
                            <a href='tel:+49 30 9155 8408'>
                                <img src={require('../../media/assets/phone-white.svg')} alt="5w-50 Phone Number" />
                            </a>
                        </div>
                    </div>
                    <div id="homepage-body" className="row align-items-center text-center">
                            <div className="col">
                                <h2>Ihre Autohaus Zukunftsexperten</h2>
                                <header className="container-fluid">
                                    <h1 className="row d-none d-xs-block">{"Wir sind die kleinste Nummer:"}</h1>
                                    <h1 className="row justify-content-center"><b>{"Die Nr.1 bei der Leadgenerierung!"}</b></h1>
                                </header>
                                {(this.state.showButton) ? <button type="button" className="btn w-primary" onClick={this.props.goToNext}>Weiter</button> : <div className="button-placeholder" />}
                                
                            </div>
                    </div>
                    <div id="homepage-footer" className="row align-items-center">
                        <div className="col-2 offset-5 text-center">
                            <button onClick={this.props.goToNext}>
                                <img src={require('../../media/assets/arrow_down_white.svg')} alt="Down Arrow" />
                            </button>
                        </div>
                        <div className="col-5 d-none d-xs-block text-right">
                            <Image 
                                src={require('../../media/img/logo-provenExpert-min.png')} 
                                webp={require('../../media/img/logo-provenExpert-min.webp')} />
                            <Image 
                                src={require('../../media/img/logo-googlePartner-min.png')} 
                                webp={require('../../media/img/logo-googlePartner-min.webp')} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Homepage;
