import React, { Component } from 'react';
import ReactPageScroller from "./lib/ReactPageScroller";
import { isMobileOnly, isBrowser, isTablet, CustomView } from 'react-device-detect';
import { withRouter } from 'react-router-dom'

import './dist/bootstrap.xxl.scss';
import './styles/styles.scss';

import {Homepage, About, References, Feedback, Presentation, Contact} from './components/Views';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      indicatorOffset: 0,
      isDarkScreen:true,
      blockScroll: false,
      activeSubpage: '',
      links: ["/", "/about", "/references", "/feedback", "/inspirations", "/contact"]
    };
    this._pageScroller = null;

    this.goToNext = this.goToNext.bind(this);
    this.manageRoutes = this.manageRoutes.bind(this);
    this.blockScrolling = this.blockScrolling.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.manageRoutes(nextProps.location.pathname);


    const route = nextProps.location.pathname;
    if (route.includes('/about')) {
      this.setState({activeSubpage:route.slice(7)});
    }
  }

  componentWillMount() {
      document.body.style.overflow = (isMobileOnly) ? null : "hidden";
      document.body.style.position = (isMobileOnly) ? null : "fixed";
  }

  componentWillUnmount(){
      document.body.style.overflow = null;
      document.body.style.position = null;
  }

  componentDidMount() {
    this.manageRoutes(this.props.location.pathname);
  }

  goToPage = (eventKey) => {
      if (this._pageScroller)
      {
        this._pageScroller.goToPage(eventKey);
      }
  };

  goToNext() {
    this.goToPage(1);
  };

  blockScrolling = (eventKey) => {
    this.setState({blockScroll:eventKey});
  };

  pageOnChange = (number) => {
      this.props.history.push(`${this.state.links[number -1]}${(number === 2) ? "/" + this.state.activeSubpage : ""}`);

      const offset = 28 * (number - 1);
      const isDarkScreen = (number === 1 || number === 3 || number === 4 || number === 5);
      this.setState({currentPage: number, indicatorOffset: offset, isDarkScreen: isDarkScreen});
  };

  manageRoutes(route) {
    switch (route) {
      case "":
      case "/":
        this.goToPage(0);
        break;
      case "/about":
      case "/about/":
        this.goToPage(1);
        break;
      case "/about/website":
      case "/about/google-ads":
      case "/about/online-marketing":
      case "/about/online-shop":
      case "/about/video-marketing":
        this.setState({activeSubpage:route.slice(7)});
        this.goToPage(1);
        break;
      case "/references":
      case "/references/":
        this.goToPage(2);
        break;
      case "/inspirations":
      case "/inspirations/":
        this.goToPage(4);
        break;
      case "/feedback":
      case "/feedback/":
        this.goToPage(3);
        break;
      case "/contact":
      case "/contact/":
        this.goToPage(5); // 5
        break;
      default:
        this.goToPage(0);
        break;
    }
  }


  render() {
    const thumb = {
      transform: `translateY(${ this.state.indicatorOffset }px) translateZ(0px)`
    }; // update on page change
    const pages = [
                      <Homepage key={0} goToNext={this.goToNext}/>,
                      <About key={1} activeSubpage={this.state.activeSubpage}/>,
                      <References key={2}/>,
                      <Feedback key={3} isActive={this.state.currentPage === 3}/>,
                      <Presentation key={4} isActive={this.state.currentPage === 4}/>,
                      <Contact key={5} blockScrolling={this.blockScrolling}/>
                    ];
    const titles = ["Startseite", "Über Uns", "Referenzen", "Feedback", "Präsentation", "Kontakt"];

    const pageIgnitionClass = (this.state.isDarkScreen) ? "side for-dark-screen" : "side";
    return (
      <div className={(isMobileOnly) ? 'isMobile' : 'isDesktop'}>
          <CustomView condition={isBrowser || isTablet}>
            <ReactPageScroller ref={c => this._pageScroller = c} pageOnChange={this.pageOnChange} animationTimer={600} blockScroll={this.state.blockScroll}>
              {pages}
            </ReactPageScroller>

            <div className={pageIgnitionClass}>
              <ul>
                { pages.map((e, i) =>
                  <li key={i} onClick={() => this.props.history.push(this.state.links[i])} className={`${(this.state.currentPage === i+1) ? "selected" : ""}`}>
                    <div key={0} className="main"></div>
                    <div key={1} className="drawer"></div>
                    <div key={2} className="label">{titles[i]}</div>
                  </li>
                )}
              </ul>
              <div className="thumb" style={thumb}></div>
            </div>
          </CustomView>

          {/* MOBILE */}

          <CustomView condition={isMobileOnly}>
            {pages}
          </CustomView>
      </div>
    );
  }
}

export default withRouter(App);
